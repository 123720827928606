import React, { memo } from 'react'
import { Eyebrow } from '@typeform/ginger/dist/components/typography/'
import { getImageAspectRatio, getImageSources } from 'utils/contentful'
import {
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
  IMAGE_LOADING,
} from '@typeform/ginger/dist/constants/image'

import {
  Container,
  ContentContainer,
  Description,
  Image,
  ImageContainer,
  Link,
  Title,
} from './card.styles'
import { TCardProps, TContainer } from './types'

const Card: React.FC<TCardProps> = ({
  image,
  eyebrow,
  title,
  description,
  link,
  aboveTheFold = false,
  aspectRatio,
  ...rest
}) => {
  let containerProps: TContainer = {
    as: 'div',
  }

  if (link) {
    containerProps = {
      as: 'a',
      href: link.url || '',
      target: link.target || undefined,
      style: link.style || undefined,
      onClick: link.onClick || undefined,
    }
  }

  return (
    <Container {...containerProps} data-qa={rest['data-qa']}>
      {image?.url && (
        <ImageContainer>
          <Image
            {...getImageSources({
              originalUrl: image.url,
              imageSizes: [500],
              sourceSizeType: 'scale',
            })}
            role={
              !(image.title || image.description) ? 'presentation' : undefined
            }
            alt={image.title || image.description || ''}
            style={{
              aspectRatio:
                aspectRatio ||
                getImageAspectRatio({
                  width: image.width,
                  height: image.height,
                }),
            }}
            fetchpriority={
              aboveTheFold ? IMAGE_FETCHPRIORITY.high : IMAGE_FETCHPRIORITY.low
            }
            loading={aboveTheFold ? IMAGE_LOADING.eager : IMAGE_LOADING.lazy}
            decoding={
              aboveTheFold ? IMAGE_DECODING.default : IMAGE_DECODING.async
            }
          />
        </ImageContainer>
      )}
      <ContentContainer>
        {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {link?.text && <Link>{link.text}</Link>}
      </ContentContainer>
    </Container>
  )
}
export default memo(Card)
