import { EMPTY_BLOCK } from 'constants/content-types'
import { IMAGE_CDN_URL } from 'constants/url'

export const SLUG_REQUEST_LIMIT = 1000
/**
 *
 * @param {*} fetcher
 * @returns {array}
 * Receives a Contentful fetcher function and will keep trying it
 * until it gets no more results.
 */
export const infiniteFetch = async fetcher => {
  let response = []
  let currentResult = [[]]

  for (
    let skip = 0;
    Array.isArray(currentResult) && currentResult.length !== 0;
    skip += SLUG_REQUEST_LIMIT
  ) {
    try {
      currentResult = await fetcher({ skip })
    } catch (error) {
      break
    }

    if (!Array.isArray(currentResult)) {
      return response
    }

    response = response.concat(currentResult)
  }
  return response
}

/**
 *
 * Some Contentful image URLs start with // so we use this function to prepend them with "https:"
 *
 * @param {string} url
 * @returns {string}
 *
 */
export const prependSecureProtocol = url => {
  if (`${url}`.startsWith('//')) {
    return `https:${url}`
  }
  return url
}

export const isContentfulImage = (url = '') => {
  return url.includes(IMAGE_CDN_URL)
}

/**
 * @ts-check
 * Generate the Contentful img src and srcset attributes
 * @type {import('./types').TImageSources}
 * **/
export const getImageSources = ({
  originalUrl = '',
  imageSizes = [], // Should always have two values; the image size for both mobile and desktop
  initialImageSize = 0, // Default `w` value — initialImageSize * 2
  sourceSizeType = 'width',
}) => {
  try {
    if (!originalUrl) {
      return null
    }

    if (!isContentfulImage(originalUrl)) {
      return {
        src: originalUrl,
      }
    }

    const url = new URL(originalUrl)
    const initialHighDPRImageSize = Boolean(initialImageSize)
      ? initialImageSize * 2 // Returns the desktop DPR 2.0 image size
      : imageSizes[0] * 2 // Returns the mobile DPR 2.0 image size
    url.searchParams.set('w', `${initialHighDPRImageSize}`) // Sets the `src` width parameter

    return {
      src: url.toString(),
      srcSet: imageSizes.reduce((acc, initialSize) => {
        const widths = [initialSize, initialSize * 2, initialSize * 3]

        return [
          ...acc,
          ...widths.map(size => {
            url.searchParams.set('w', `${size}`)

            if (sourceSizeType === 'scale') {
              return `${url.toString()} ${size / initialSize}x`
            }

            return `${url.toString()} ${size}w`
          }),
        ]
      }, []),
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)

    return null
  }
}

export const getImageAspectRatio = (image = {}) => {
  if (!image.width || !image.height) {
    return undefined
  }

  return `${Number(image.width / image.height).toFixed(2)} / 1`
}

export const hasEmptyContentType = entry =>
  entry?.sys?.contentType?.sys?.id === EMPTY_BLOCK
