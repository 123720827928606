import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { typography } from '@typeform/ginger/dist/util/styles-mixins'
import styled, { css } from 'styled-components'
import Text, {
  SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import { Eyebrow } from '@typeform/ginger/dist/components/typography'
import { ImageWithLazyAttributes } from 'components/atoms/images'

import { TContainer } from './types'

export const Image = styled(ImageWithLazyAttributes)`
  width: 100%;
`

export const Container = styled.div<TContainer>`
  content-visibility: auto;

  ${mediaQueries.sm`
    display: flex;
    flex-direction: row;
    align-items: center;
  `}

  ${mediaQueries.md`
    display: block;
  `}

  ${({ as }) =>
    as === 'a' &&
    css`
      ${Image} {
        transition: transform 200ms ease-in-out;
      }

      &:hover {
        ${Image} {
          transform: scale(1.1);
        }
      }
    `}
`

export const ImageContainer = styled.div`
  line-height: 0;
  margin-bottom: ${spacing(4)};
  overflow: hidden;

  ${mediaQueries.sm`
    flex: 0 0 50%;
    margin-bottom: 0;
  `}

  ${mediaQueries.md`
    margin-bottom: ${spacing(4)};
  `}
`

export const Title = styled(Text).attrs(() => ({
  size: SIZE.M,
  largeViewportsSize: SIZE.L,
  extraLargeViewportsSize: SIZE.L,
  emphasised: true,
}))`
  ${typography.lineClamp(3)}

  &:not(:last-child) {
    margin-bottom: ${spacing(1)};
  }
`

export const Description = styled(Text).attrs(() => ({
  size: SIZE.S,
  extraLargeViewportsSize: SIZE.M,
}))`
  ${typography.lineClamp(4)}

  &:not(:last-child) {
    margin-bottom: ${spacing(1)};
  }
`

export const Link = styled(Text).attrs(() => ({
  size: SIZE.S,
  extraLargeViewportsSize: SIZE.M,
  emphasised: true,
  underlined: true,
}))`
  margin-top: ${spacing(2)};
`

export const ContentContainer = styled.div`
  ${Eyebrow} {
    margin-bottom: ${spacing(2)};
  }

  ${mediaQueries.sm`
    flex: 0 0 50%;
    padding: ${spacing(0, 3)};
  `}

  ${mediaQueries.md`
    padding: 0;
  `}
`
