// #region Deprecated
export const MEDIA_LEGACY = 'mlp-media'
// #endregion

// #region Modules
export const CONTENT_GRID_MODULE = 'componentContentGridModule'
export const CONTEXT_SWITCHER_MODULE = 'componentContextSwitcher'
export const HEADLINE_MODULE = 'mlp-headline-module'
export const IMAGE_ACCORDION_MODULE = 'mlpImageAccordionModule'
export const MEDIA_TEXT_MODULE = 'componentMediaTextModule'
export const LOGOS_MODULE = 'mlp-logos-module'
export const MEDIA_BIG_MODULE = 'componentMediaBigModule'
export const MLP_FAQS_MODULE = 'mlp-faqs'
export const MLP_FAQS = 'mlp-faq'
export const RICH_TEXT_MODULE = 'mlp-rich-text-module'
export const SCROLLING_CARDS_MODULE = 'mlp-scrolling-cards-module'
export const TESTIMONIAL_MODULE = 'mlp-testimonial-module'
export const TESTIMONIALS_MODULE = 'mlp-testimonials-module'
export const TYPEFORM_EMBED_MODULE = 'mlp-typeform-embed-module'
export const TYPEFORM_TEMPLATE_MODULE = 'mlp-typeform-template-module'
export const RATINGS_MODULE = 'mlp-ratings-module'
export const PRICING_PLANS_MODULE = 'mlp-pricing-plans-module'
export const CARD_GRID_MODULE = 'hc-card-grid-module'
export const LINK_CARD_MODULE = 'hc-link-card'
export const VIDEO_LINK_CARD_MODULE = 'hc-video-link-card'
export const EMPTY_BLOCK = 'emptyExperimentBlock'
export const HERO_MODULE = 'componentHeroModule'
export const CONDITIONAL_MODULE = 'componentConditionalModule'
// #endregion

// #region Sections
export const CAROUSEL_SECTION = 'mlp-carousel-section'
export const CASE_STUDY_SECTION = 'mlp-case-study-section'
export const CTA_SECTION = 'mlp-cta-section'
export const GRID_SECTION = 'mlp-grid-section'
export const RAW_HTML_SECTION = 'mlp-raw-html-section'
export const RICH_TEXT_SECTION = 'mlp-rich-text-section'
export const TRUSTPILOT_SECTION = 'mlp-trustpilot-section'
export const TYPEFORM_SECTION = 'mlp-typeform-section'
export const REFER_A_FRIEND_LINK_GENERATOR_SECTION =
  'mlp-refer-a-friend-link-generator-section'
// #endregion

// #region Topics
export const LOTTIE_ANIMATION = 'topicLottieAnimation'
export const MEDIA = 'topicMedia'
export const MEDIA_IMAGE = 'topicMediaImage'
export const MEDIA_VIDEO = 'topicMediaVideo'
export const MEDIA_TYPEFORM = 'topicMediaTypeform'
export const MEDIA_EMBED = 'topicMediaEmbed'
// #endregion

// #region Other
export const BANNER = 'banner'
export const FEEDBACK_FORM = 'componentFeedbackForm'
export const HELP_CENTER_VIDEO = 'hc-typeformHelpcenterVideo'
export const LIST = 'mlp-list'
export const MEDIA_VIDEOASK = 'media-videoask'
export const MEDIA_WISTIA = 'media-wistia'
export const MEDIA_YOUTUBE = 'media-youtube'
export const MEDIA_IMAGE_WITH_TYPEFORM_POPUP = 'mediaImageWithTypeformPopup'
export const MLP_BLOCKS_EXPERIMENT = 'mlpLandingPageBlocksExperiment'
export const MLP_CLOSING_MODULE_EXPERIMENT =
  'mlpLandingPageClosingModuleExperiment'
export const MLP_HERO_EXPERIMENT = 'mlpLandingPageHeroExperiment'
export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
}
export const QUOTE = 'blog-quote'
export const VARIATION_CONTAINER = 'VariationContainer'
export const VARIATION_SWITCHER = 'VariationSwitcher'
export const MLP_ENTERPRISE_SECTION = 'mlpEnterpriseSection'
// #endregion

export const BLOG_AUTHOR_CONTENT_TYPE_ID = 'blog-author'
export const BLOG_ARTICLE_CONTENT_TYPE_ID = 'blog-article'
export const BLOG_CATEGORY_CONTENT_TYPE_ID = 'blog-category'
export const BLOG_TAG_CONTENT_TYPE_ID = 'tag'

export const MLP_CTA = 'mlp-cta'
export const MLP_CTA_EXPERIMENT = 'mlpCtaExperiment'
export const BLOG_ARTICLES_GRID = 'componentBlogArticlesGrid'
export const BLOG_ARTICLES_GRID_WITH_FILTERS =
  'componentBlogArticlesGridWithFilters'
export const CTA_MODULE = 'componentCtaModule'

export const TEMPLATE_GALLERY_SIDE_MENU_SECTION_GROUP =
  'topicTemplatesSideMenuSectionGroup'
export const TOPIC_SOCIALS_SIGNUP_ACTION_BAR = 'topicSocialsSignupActionBar'
