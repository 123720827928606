import React, { memo, useCallback, useMemo } from 'react'
import chunk from 'lodash.chunk'
import useTranslation from 'next-translate/useTranslation'
import { SIZE as HEADING_SIZE } from '@typeform/ginger/dist/components/typography/headline/next'
import { SIZE as TEXT_SIZE } from '@typeform/ginger/dist/components/typography/text/next'
import { onTrackItemClickEvent } from '@typeform/ginger/dist/util/tracking'
import ActionBar, {
  TActionBarMouseEventHandler,
} from 'components/molecules/action-bar'
import { trackEvent, trackExperimentEvent } from 'components/tracking'
import { TCta } from 'models/cta-model.types'

import {
  HeadingsContainer,
  Heading,
  Subheading,
  Section,
  ActionBarContainer,
  CardsContainer,
  CardContainer,
} from './content-grid-module.styles'
import { TContentGridModuleProps } from './types'
import Card from './card'

const ContentGridModule: React.FC<TContentGridModuleProps> = ({
  heading,
  subheading,
  actionBar,
  cards,
  children,
  cardAspectRatio,
  ...rest
}) => {
  const { t } = useTranslation()

  const handleActionClick: TActionBarMouseEventHandler = useCallback(
    ({ link }) => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
    },
    []
  )

  const onCtaClick = useCallback(
    (link: TCta) => () => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
    },
    []
  )

  const cardItems = useMemo(() => {
    if (!cards?.length) return undefined
    const chunckCount = cards.length % 3 === 0 ? 3 : 2

    const chunks = chunk(cards, chunckCount)
    return chunks.map(cards =>
      cards.map(({ id, image, link, ...card }, index) => (
        <CardContainer key={id} index={index} cardsLength={cards.length}>
          <Card
            {...card}
            image={image}
            aspectRatio={cardAspectRatio}
            link={
              link && {
                ...link,
                url: link.url,
                text: link.text ?? t('common.link.learn-more.label'),
                onClick: onCtaClick(link),
              }
            }
          />
        </CardContainer>
      ))
    )
  }, [cards, cardAspectRatio, t, onCtaClick])

  const content = children || cardItems
  const hasHeadings = Boolean(heading || subheading)

  return (
    <Section {...rest} data-qa='content-grid-module'>
      {hasHeadings && (
        <HeadingsContainer>
          {heading && (
            <Heading
              element='h2'
              size={HEADING_SIZE.TWO}
              mediumViewportsSize={HEADING_SIZE.THREE}
              largeViewportsSize={HEADING_SIZE.FOUR}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Subheading size={TEXT_SIZE.S} largeViewportsSize={TEXT_SIZE.M}>
              {subheading}
            </Subheading>
          )}
        </HeadingsContainer>
      )}
      {content && <CardsContainer>{content}</CardsContainer>}
      {actionBar && (
        <ActionBarContainer>
          <ActionBar {...actionBar} onClick={handleActionClick} />
        </ActionBarContainer>
      )}
    </Section>
  )
}

export default memo(ContentGridModule)
