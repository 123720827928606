import { spacing } from '@typeform/ginger/dist/util/spacing'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'
import Grid from 'components/atoms/grid-container/next'
import Headline from '@typeform/ginger/dist/components/typography/headline/next'
import Text from '@typeform/ginger/dist/components/typography/text/next'
import ModuleContainer, {
  TModuleContainerProps,
} from 'components/modules/module-container'
import { Container as ActionBar } from 'components/molecules/action-bar'

export const Section = styled(ModuleContainer)<TModuleContainerProps>`
  padding: ${spacing(8, 0)};

  ${mediaQueries.sm`
    padding: ${spacing(9, 0)};
  `}

  ${mediaQueries.md`
    padding: ${spacing(10, 0)};
  `}

  ${mediaQueries.xl`
    padding: ${spacing(12, 0)};
  `}
`

export const HeadingsContainer = styled(Grid)`
  text-align: center;
  margin-bottom: ${spacing(10)};
  row-gap: ${spacing(2)};

  ${mediaQueries.lg`
    row-gap: ${spacing(3)};
  `}
`

const baseHeadingsStyles = css`
  grid-column: 1 / -1;

  ${mediaQueries.md`
    grid-column: 3 / -3;
  `}

  ${mediaQueries.lg`
    grid-column: 4 / -4;
  `}
`

export const Heading = styled(Headline)`
  ${baseHeadingsStyles}
`

export const Subheading = styled(Text)`
  ${baseHeadingsStyles}
`

export const CardsContainer = styled(Grid)`
  row-gap: ${spacing(7)};

  ${mediaQueries.md`
    row-gap: ${spacing(8)};
  `}
`

export const CardContainer = styled.div<{
  cardsLength: number
  index: number
}>`
  grid-column: 1 / -1;

  ${mediaQueries.md`
    grid-column: ${({
      index,
      cardsLength,
    }: {
      index: number
      cardsLength: number
    }) => {
      switch (cardsLength) {
        case 1:
          return '5 / span 4'
        case 2:
          return index === 0 ? '3 / span 4' : 'span 4'
        default:
          return 'span 4'
      }
    }};
  `}
`

export const ActionBarContainer = styled(Grid)`
  margin-top: ${spacing(10)};

  ${ActionBar} {
    grid-column: 1 / -1;
  }

  ${mediaQueries.sm`
    justify-items: center;
  `}
`
