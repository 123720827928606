import { NAMESPACES } from 'constants/locales'

import React, { memo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { routes } from 'utils/routes'
import ContentGridModule from 'components/modules/content-grid-module'
import { trackExperimentEvent } from 'components/tracking'
import { getStaticAsset } from 'utils/assets'

import TrackingLocation, {
  TrackingLocationNames,
} from '../common/tracking-location'
import { HOMEPAGE_REFRESH_TEST_ID } from '../constants'

const ContentGridSection = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)
  const handlePrimaryClick = () => {
    trackExperimentEvent({
      testId: HOMEPAGE_REFRESH_TEST_ID,
      name: 'homepage_refresh_content_grid_primary_cta_click',
    })
  }
  const handleTertiaryClick = () => {
    trackExperimentEvent({
      testId: HOMEPAGE_REFRESH_TEST_ID,
      name: 'homepage_refresh_content_grid_tertiary_cta_click',
    })
  }
  return (
    <TrackingLocation name={TrackingLocationNames.ContentGrid}>
      <ContentGridModule
        heading={t('homepage.refresh.content-grid-section.title')}
        actionBar={{
          primaryLink: {
            label: t('homepage.refresh.content-grid-section.primary-cta.label'),
            href: routes(lang).pricing,
            onClick: handlePrimaryClick,
          },
          tertiaryLink: {
            label: t(
              'homepage.refresh.content-grid-section.tertiary-cta.label'
            ),
            href: routes(lang).templates.path,
            onClick: handleTertiaryClick,
          },
        }}
        cards={[
          {
            id: 'content-grid-card-0',
            title: t('homepage.refresh.content-grid-section.card-0.title'),
            description: t(
              'homepage.refresh.content-grid-section.card-0.description'
            ),
            image: {
              url: getStaticAsset(
                'homepage-refresh/product-description/designed-to-attract.webp'
              ),
            },
          },
          {
            id: 'content-grid-card-1',
            title: t('homepage.refresh.content-grid-section.card-1.title'),
            description: t(
              'homepage.refresh.content-grid-section.card-1.description'
            ),
            image: {
              url: getStaticAsset(
                'homepage-refresh/product-description/deeper-insights.webp'
              ),
            },
          },
          {
            id: 'content-grid-card-2',
            title: t('homepage.refresh.content-grid-section.card-2.title'),
            description: t(
              'homepage.refresh.content-grid-section.card-2.description'
            ),
            image: {
              url: getStaticAsset(
                'homepage-refresh/product-description/unique-data.webp'
              ),
            },
          },
        ]}
      />
    </TrackingLocation>
  )
}

ContentGridSection.displayName = 'ContentGridSection'

export default memo(ContentGridSection)
